import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyBK7imkK7IEuHyS-Z5T_GYY5y6qpX6EUKo",
  authDomain: "k-visa-988c3.firebaseapp.com",
  projectId: "k-visa-988c3",
  storageBucket: "k-visa-988c3.appspot.com",
  messagingSenderId: "697477023277",
  appId: "1:697477023277:web:526ead890a251ace159aa4",
  measurementId: "G-8TH7XPE5VV"
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
