import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import { useAuth } from 'src/AuthProvider'; // Assuming AuthProvider is in src directory
import NotificationMessageTable from 'src/content/applications/Notification/NotificationMessageTable';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Login
const LoginForm = Loader(lazy(() => import('src/components/Login/LoginForm')));


const Stat = Loader(lazy(() => import('src/content/applications/Stat')));

const InvitationCode = Loader(lazy(() => import('src/content/applications/InvitationCode')));


const User = Loader(lazy(() => import('src/content/applications/Member')));
const MemberDetail = Loader(
  lazy(() => import('src/content/applications/Member/MemberDetail'))
);
const UserList = Loader(
  lazy(() => import('src/content/applications/Member/MemberTable'))
);


const Community = Loader(
  lazy(() => import('src/content/applications/Community'))
);
const DocumentTable = Loader(
  lazy(() => import('src/content/applications/Community/DocumentTable'))
);
const DocumentDetail = Loader(
  lazy(() => import('src/content/applications/Community/DocumentDetail'))
);
const AddDocument = Loader(
  lazy(() => import('src/content/applications/Community/AddDocument'))
);


const Job = Loader(
  lazy(() => import('src/content/applications/Job'))
);
const JobTable = Loader(
  lazy(() => import('src/content/applications/Job/JobTable'))
);
const JobDetail = Loader(
  lazy(() => import('src/content/applications/Job/JobDetail'))
);


const Exam = Loader(
  lazy(() => import('src/content/applications/Exam'))
);
const ExamTable = Loader(
  lazy(() => import('src/content/applications/Exam/ExamTable'))
);
const ExamDetail = Loader(
  lazy(() => import('src/content/applications/Exam/ExamDetail'))
);


const Banner = Loader(
  lazy(() => import('src/content/applications/Banner'))
);
const BannerTable = Loader(
  lazy(() => import('src/content/applications/Banner/BannerTable'))
);
const BannerDetail = Loader(
  lazy(() => import('src/content/applications/Banner/BannerDetail'))
);

const News = Loader(
  lazy(() => import('src/content/applications/News'))
);
const NewsTable = Loader(
  lazy(() => import('src/content/applications/News/NewsTable'))
);
const NewsDetail = Loader(
  lazy(() => import('src/content/applications/News/NewsDetail'))
);

const Benefit = Loader(
  lazy(() => import('src/content/applications/Benefit'))
);
const BenefitTable = Loader(
  lazy(() => import('src/content/applications/Benefit/BenefitTable'))
);
const BenefitDetail = Loader(
  lazy(() => import('src/content/applications/Benefit/BenefitDetail'))
);

const Report = Loader(
  lazy(() => import('src/content/applications/Report'))
);
const ReportTable = Loader(
  lazy(() => import('src/content/applications/Report/ReportTable'))
);


const Notification = Loader(
  lazy(() => import('src/content/applications/Notification'))
);
const NotificationTable = Loader(
  lazy(() => import('src/content/applications/Notification/NotificationMessageTable'))
);
const NotificationDetail = Loader(
  lazy(() => import('src/content/applications/Notification/NotificationMessageDetail'))
);



// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);



const ProtectedSidebarLayout: React.FC = () => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <SidebarLayout /> : <Navigate replace to="/login" />;
};

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <LoginForm />
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate replace to="management/user" />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'management',
    element: <ProtectedSidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="stat" replace />
      },

      {
        path: 'stat',
        element: <Stat />
      },
      {
        path: 'invitation-code',
        element: <InvitationCode />
      },

      {
        path: 'user',
        element: <User />,
        children: [
          { path: '', element: <UserList /> },
          { path: 'add', element: <MemberDetail /> },
          { path: ':id', element: <MemberDetail /> }
        ]
      },

      {
        path: 'community',
        element: <Community />,
        children: [
          { path: '', element: <DocumentTable /> },
          { path: 'add', element: <AddDocument /> },
          { path: ':id', element: <DocumentDetail /> }
        ]
      },

      {
        path: 'job',
        element: <Job />,
        children: [
          { path: '', element: <JobTable /> },
          { path: 'add', element: <JobDetail /> },
          { path: ':id', element: <JobDetail /> }
        ]
      },

      {
        path: 'exam',
        element: <Exam />,
        children: [
          { path: '', element: <ExamTable /> },
          { path: 'add', element: <ExamDetail /> },
          { path: ':id', element: <ExamDetail /> }
        ]
      },

      {
        path: 'banner',
        element: <Banner />,
        children: [
          { path: '', element: <BannerTable /> },
          { path: 'add', element: <BannerDetail /> },
          { path: ':id', element: <BannerDetail /> }
        ]
      },

      {
        path: 'news',
        element: <News />,
        children: [
          { path: '', element: <NewsTable /> },
          { path: 'add', element: <NewsDetail /> },
          { path: ':id', element: <NewsDetail /> }
        ]
      },

      {
        path: 'benefit',
        element: <Benefit />,
        children: [
          { path: '', element: <BenefitTable /> },
          { path: 'add', element: <BenefitDetail /> },
          { path: ':id', element: <BenefitDetail /> }
        ]
      },

      {
        path: 'report',
        element: <Report />,
        children: [
          { path: '', element: <ReportTable /> }
        ]
      },

      {
        path: 'notification',
        element: <Notification />,
        children: [
          { path: '', element: <NotificationTable /> },
          { path: ':id', element: <NotificationDetail /> }
        ]
      },

    ]
  }
];

export default routes;
